import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import fb from 'firebaseConfig';

import PATH from 'routes/path';
import Landing from 'pages/Landing';

const Router = () => {  
  useEffect(() => {
    fb.analytics().logEvent(window.location.pathname + window.location.search);
  }, []);

  const getRoute = (path, component) => (
    <Route
      exact
      path={path}
      component={component}
    />
  );

  return (
    <BrowserRouter>
      <Switch>
        {getRoute(PATH.LANDING, Landing)}
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
