
/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { StyledDialog, Content } from './styles';

// Modal de Termos de uso
const EulaModal = ({
  open,
  onClose,
}) => {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
    >
      <Content>
        <IconButton
          color="secondary"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <h2>LOIT-FGTS</h2>
        <div>
          <p>1. Esta Declaração de Política de Privacidade e proteção de dados tem a finalidade de:</p>

          <p>a) atender às disposições da Lei Geral de Proteção de Dados (Lei 13.709/2018), que estabelece as regras para a proteção de dados pessoais no Brasil. Para sua inmfomração, o conteúdo da lei pode ser acessado aqui: <a target="_blank" href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm">http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm</a></p>

          <p>b) explicitar o compromisso da TIA TIKAL INTELIGÊNCIA  ARTIFICIAL LTDA., CNPJ 22.125.577/0001-62 com endereço na Av. Queiroz Queiroz Filho, 1700, CJ 404-E, São Paulo, SP. com a segurança e a privacidade das informações recebidas de seus usuários, e esclarecer como as informações de dados pessoais dos usuários são coletadas e tratadas.</p>

          <p>c) explicitar que se aplicam a todos os serviços comercializados pela TIKAL TECH sob a marca LOIT, destinada ao exercício de direitos diretamente pelo público leigo, sem a intermediação de profissionais da advocacia ou outras áreas.</p>

          <p>2. É nossa intenção explicar de forma clara e simples essas regras e os nossos compromissos. Nesta declaração de proteção de dados, nós primeiro definimos alguns termos e depois descrevemos como é que nós lidamos com seus dados pessoais:</p>

          <p>que dados seus recebemos, armazenamos e tratamos, e quais as maneiras como coletamos dados pessoais</p>

          <p>qual é o tipo de tratamento que damos aos seus dados</p>

          <p>com qual base legal recebemos, armazenamos e tratamos os seus dados</p>

          <p>como lidamos com seu consentimento</p>

          <p>quais os parceiros da Tikal Tech que tem acesso aos seus dados</p>

          <p>durante quanto tempo retemos os seus dados,</p>

          <p>como você pode entrar em contato conosco sobre a proteção de seus dados ou nos avisar sobre algum incidente ou reclamação</p>

          <p>quais os seus direitos em relação aos seus dados, bem como quem são os responsáveis pelo cuidado com seus dados na Tikal Tech</p>

          <p>3. Nossa política de privacidade e proteção de dados se aplica a todos os momentos em que coletamos seus dados, ou seja, quando você baixa nossos aplicativos no seu celular/tablet, navega por nossos websites, solicita e usa nossos serviços ou entra em contato com a TIKAL TECH por um dos nossos canais digitais  de comunicação da LOIT. Todas as vezes em que você notar um trecho em negrito nesta declaração de política de privacidade DECLARAÇÃO DE PRIVACIDADE, trata-se de uma situação que você precisa estar ciente.</p>

          <p>4.  São considerados seus dados pessoais as informações que identifiquem você diretamente ou possam levar à sua identificação. Em princípio, só podemos realizar qualquer tratamento com seus dados pessoais se tivermos seu consentimento. Quando você adquire qualquer dos nossos serviços, está nos autorizando a utilizar os dados pessoais para a realização desses serviços, ou o cumprimento de nosso contrato, ou outro tipo específico de consentimento que será obtido caso a caso. Nós podemos também utilizar seus dados pessoais para cumprir obrigações legais ou judiciais ou para realizar estudos, pesquisas e operações anteriores a você nos contratar, desde que você tenha autorizado a realizar esta operação, mesmo sem a obrigação de nos contratar posteriormente.</p>

          <p>5. Todas as informações são coletadas mediante conhecimento e consentimento dos usuários e são utilizadas para atender à finalidade do serviço contratado. De forma alguma fazemos coleta de dados de forma clandestina, secreta ou fraudulenta. Não consideramos captcha um mecanismo de impedimento de acesso aos dados, mas simplesmente um mecanismo de impedimento da automação desse acesso, de forma que não há fraude em captar dados quando há captcha. No entanto, sendo parte de nossos serviços o fornecimento de informações a você e sobre você, é necessário que você esteja ciente e consinta em que, a partir das informações fornecidas, nós poderemos, a fim de cumprir o contratado, ou mesmo para pesquisar dados pré-contratuais a seu pedido e lhe informar  sobre dados e/ou pré-análises que possam lhe esclarecer para que você possa decidir se nos contrata ou não, (a) obter por nossa conta e a partir de nossos sistemas, outras informações referentes a você ou a terceiro, (b) transmitir a terceiros parceiros (abaixo identificados) seus dados pessoais, para que efetuem alguma operação de tratamento que integre de forma terceirizada e sob nossa responsabilidade, o nosso fluxo de trabalho, sempre em benefício de seu interesse, que é justamente o escopo dos serviços contratados ou a serem contratados. Por outro lado, a fim de cumprir o contrato realizado com você, confiaremos na exatidão e correção dos dados que nos fornecer, e será sua a decisão de fornecer tais dados, e de sua responsabilidade o fornecimento exato dos dados. Dados inexatos levam a resultados equivocados mas não exime o cliente do pagamento do contrato.</p>

          <p>6.    Você poderá retirar seu consentimento para o uso de seus dados pessoais sem qualquer ônus e a qualquer momento, ou pedir que eles sejam corrigidos. Para isso nós lhe informaremos quais os dados pessoais que dispomos a seu respeito, e qual o histórico de seus dados pessoais nos nossos bancos de dados. O usuário poderá também solicitar a revisão e correção de seus dados  e a qualquer tempo. Para isso, basta entrar em contato pelo canal exclusivo para o atendimento a solicitações de proteção de dados pessoais que está listada abaixo.</p>

          <p>7. Para o pagamento de nossos serviços poderemos coletar a bandeira, nome registrado e o número de seu cartão de crédito ou débito e data de expiração, ou dados necessários para a expedição de boletos, como endereço e CPF por exemplo. Podemos também solicitar seus dados de endereço e telefone, para contato e localização e remessa de faturas, cópias e outros documentos.</p>

          <p>8.    Note que nos itens 4, 5, 6 e 7 acima nós mencionamos seu consentimento quando os dados são fornecidos por você, havendo ou não a intenção de nos contratar. Assim, a coleta de seus dados proveio da divulgação feita por você livremente, e a exclusão de seus dados (se não tivermos o direito de retê-los por alguma outra autorização legal), será eficaz a partir unicamente de sua vontade.</p>

          <p>9.    No entanto, nós vendemos serviços digitais na modalidade software as a service (SAAS), e necessitamos coletar dados para vender, realizar e entregar nossos serviços, dentre estes, dados pessoais. Nossos serviços estão baseados exclusivamente em tratamento de informação por meio de automação ou inteligência artificial, em fontes públicas, abertas e acessíveis de dados, especialmente diários oficiais e sítios de internet de Tribunais e repartições públicas. Não coletamos informações que não sejam acessíveis e não estejam disponibilizadas como públicas na origem de onde as coletamos. Dentre esses dados públicos, podem existir dados pessoais seus ou de terceiros, mas que estão disponíveis para serem coletados e tratados por quem quer que acesse tais fontes.</p>

          <p>10. Também para a utilização de nossos serviços, podemos coletar informações de processos públicos e outras informações constantes de bancos de dados públicos e acessíveis, como números de processos judiciais e/ou administrativos, nome e posição processual das partes, registros públicos como registro na OAB ou número de identificação de segurado, funcionário ou outro número que permita a busca de informações referentes ao usuário ou ao processo de interesse do usuário.</p>

          <p>11. Se obtivermos os seus dados a partir dessas fontes, o regime de privacidade e de proteção de dados pessoais é diferente.</p>

          <p>12.    Na hipótese de coletarmos dado dessas fontes públicas, nosso compromisso é o de utilizarmos tais dados exclusivamente em cumprimento ao contrato que celebramos com nossos clientes em nossos diversos serviços, e não utilizaremos tais dados senão nos limites desses contratos e com a finalidade de otimizar o fluxo de informações (seja por automação, seja por utilização de Inteligência Artificial) que os nossos clientes teriam mesmo sem a nossa intervenção. A permissão legal para usarmos dados de fontes públicas encontra-se no § 3º do art. 7º da Lei 13.709/2018. Ainda assim, você poderá nos contatar, e, caso demonstre que a divulgação naquela fonte pública é irregular,  nós excluiremos seus dados pessoais das nossas informações e da execução dos contratos com nossos clientes. No entanto, neste caso, será nossa a prerrogativa balancear o atendimento ao contrato com nossos clientes e as suas razões para a exclusão dos dados provenientes dessas fontes públicas, muito embora nós deveremos informar a você nossas razões.</p>

          <p>13.    Nesta hipótese de coleta de dados de fontes públicas, um tipo específico de dados pessoais pode ser coletado. Trata-se de dados pessoais sensíveis, que se referem a origem racial e étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, nos termos do art. 5º, II, da Lei 13.709/2018. Nós não solicitamos a você que nos forneça dados pessoais sensíveis, a não ser que algum serviço deles necessite, e você será informado disso antes de contratar e consentir. No entanto, a partir de fontes públicas, esses dados podem vir a ser coletados pelos nossos processos automatizados, uma vez que estão publicados, ainda que irregularmente (como por exemplo a publicação no diário oficial ou sites de tribunais de dados que deveriam estar em segredo de justiça). Nesta hipótese, basta você nos contatar no canal específico abaixo publicado, para que nós façamos a exclusão imediata desses dados, ainda que eles pertençam a contrato com cliente nosso.</p>

          <p>14.    Os dados coletados são utilizados para prover os serviços contratados pelo usuário, mas também servirão para viabilização de ofertas e serviços da TIKAL TECH para clientes LOIT, melhorias nos produtos atuais, promoções, lançamentos, ofertas, novos produtos, melhorias e funcionalidades, bem como medidas de prevenção e combate a fraudes e informações sobre problemas técnicos ou de segurança nos processos de identificação e autenticação, faturamento e remessa de documentos, como boletos e notas fiscais. Você também receberá informações correlatas aos serviços,  cursos, postagens em blogs, artigos e material informativo referente a direito e tecnologia legal, aos serviços e atividades da TIKAL TECH, tudo isso em seu email ou outro mecanismo de informação, como uma página web do usuário na plataforma de serviços da TIKAL TECH. Você poderá a qualquer momento requerer a retirada de seu email da lista de divulgação de tais materiais a qualquer momento.</p>

          <p>15. Você também será convidado a participar de pesquisas, cuja participação é voluntária. As respostas poderão ser individualizadas para prevenir votos múltiplos.</p>

          <p>16. A TIKAL TECH assume o compromisso de que os dados fornecidos pelo usuário nunca serão transferidos a terceiros ou usados para finalidades diferentes daquelas para as quais foram coletadas, a não ser que exista consentimento prévio seu ou uma determinação legal ou judicial de compartilhar tais dados.</p>

          <p>17.    Os websites da TIKAL TECH utilizam cookies (do Google Analytics) para acompanhar as interações com o visitante. Esses cookies são usados para armazenar informações, como o horário em que a visita atual ocorreu, se o visitante já esteve no site antes e que site indicou este site ao visitante. Os navegadores não compartilham seus cookies entre domínios. Um cookie é um arquivo colocado em seu computador para rastrear movimentos dentro do site, como visitas às páginas e anúncios. Cookies não armazenam informações pessoais sem que o usuário as tenha fornecido e não coletam informações registradas em seu computador</p>

          <p>18.    Os websites da TIKAL TECH e especialmente aqueles operados sob a marca LOIT utilizam  a ferramenta Google Analytics para avaliar a audiência do site e melhorar constantemente a oferta de conteúdo para nossos usuários e clientes. Essa ferramenta possui Condições Gerais de Uso e Política de Privacidade, conforme detalhado no link do Google para consulta: <a target="_blank" href="http://www.google.com/analytics/terms/br.html">http://www.google.com/analytics/terms/br.html</a>.</p>
          <p>O usuário pode optar para que as suas interações em nossos sites não sejam armazenadas pelo Google Analytics, comunicando-se com a TIKAL TECH pelos canais de comunicação próprios para este fim, que você acessará no link CANAIS DE COMUNICAÇÃO e INFORMAÇÃO LOIT.</p>

          <p>19. Ao navegar nos websites, baixar o aplicativo ou solicitar e utilizar os serviços da TIKAL TECH sob a marca LOIT ou outras marcas da TIKAL TECH, o usuário autoriza expressamente a TIKAL TECH a compartilhar algumas das suas informações com terceiros e com empresas do mesmo grupo econômico ou que participem da cadeia de produção dos serviços, para continuar usufruindo do serviços contratados e eventuais melhorias, ofertas e novas funcionalidades e produtos agregados aos serviços experiência.</p>

          <p>20.    A TIKAL TECH poderá compartilhar dados com terceiros para conseguir prestar os serviços contratados sob a marca LOIT, tais como, login, identificação e autenticação; acesso a bancos de dados compilados de informações de bancos de dados públicos, judiciais ou administrativos, incluindo, mas não se limitando a sites de tribunais, repartições administrativas e diários oficiais; operacionalização de novos produtos; prevenção e combate a fraudes ou hackeamento, problemas técnicos ou de segurança nos processos de identificação, dentre outros.</p>

          <p>22.    Os dados pessoais que são compartilhados com esses parceiros estão restritos ao tratamento necessário para a realização das operações para as quais os parceiros foram contratados, não havendo autorização para a utilização com qualquer outra finalidade.</p>

          <p>23.    Seus dados pessoais somente serão conservados conosco, isto é, armazenados e tratados (a) enquanto houver um contrato em vigor com a TIKAL TECH (e) enquanto houver necessidade de conservá-los para o cumprimento de determinação legal, judicial ou para proteção de nossos direitos.  Ao fim deste período em que os dados devem ser conservados, eles serão eliminados, com a ressalva de que você poderá consentir na manutenção de alguns dados para futuros contatos ou continuação do relacionamento de informação e fidelização.</p>

          <p>24.    Os parceiros da TIKAL TECH com os quais os dados são compartilhados para as finalidades acima mencionadas são listados no link CANAIS DE COMUNICAÇÃO LOIT. Sempre que houver modificação, serão atualizados naquela página para sua informação</p>

          <p>25. A TIKAL TECH usa diversas medidas de segurança para garantir a integridade de suas informações, como padrões de segurança de informação mundialmente aceitos praticados pela indústria quando coleta e armazena seus dados pessoais e criptografia de dados padrão da Internet.</p>

          <p>26. Nossas medidas de segurança são listadas no link CANAIS DE COMUNICAÇÃO LOIT. Sempre que houver modificação, serão atualizados naquela página para sua informação</p>

          <p>28. Por determinação legal (arts 37 e 41 da LGPD) a TIKAL TECH indica o CONTROLADOR DOS DADOS PESSOAIS e o OPERADOR DOS DADOS PESSOAIS. Tanto os dados do controlador quanto do operador de dados pessoais são listadas no link CANAIS DE COMUNICAÇÃO LOIT. Sempre que houver modificação, serão atualizados naquela página para sua informação</p>

          <p>29. A TIKAL TECH indica também o ENCARREGADO PELO TRATAMENTO DE DADOS PESSOAIS (também conhecido como Oficial de Proteção de Dados ou DPO - Data Protection Officer). É esta pessoa que estará (a) encarregada de atender quaisquer reclamações ou reivindicações referentes a proteção de dados, (b) obter informações do operador e encarregados de dados da TIKAL TECH E (c) representar a TIKAL TECH perante a Agência Nacional de Proteção de Dados. O DPO da TIKAL TECH está identificado no link CANAIS DE COMUNICAÇÃO LOIT. Sempre que houver modificação, serão atualizados naquela página para sua informação.</p>
        </div>
      </Content>
    </StyledDialog>
  );
};

EulaModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EulaModal;