/* eslint-disable object-curly-newline */
/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';

import { StyledButton } from './styles';

/*
  ## Botões (Contained e Outlined) ##
*/

export const Button = ({ children, variant, loading, disabled, startIcon, ...params }) => (
  <StyledButton
    {...params}
    color="primary"
    variant={variant}
    disabled={loading || disabled}
    startIcon={startIcon}
  >
    {
      loading ? (
        <CircularProgress
          size={20}
          color="neutral"
        />
      ) : children
    }
  </StyledButton>
);

Button.defaultProps = {
  variant: 'contained',
  startIcon: null,
  loading: false,
  disabled: false,
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  startIcon: PropTypes.any,
  variant: PropTypes.oneOf(['contained', 'outlined']),
};
