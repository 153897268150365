import styled from 'styled-components';

import { COLOR } from 'assets/styles/colors';
import BREAKPOINT from 'assets/styles/breakpoints';

export const Wrapper = styled.div`
  position: relative;
  h1 {
    font-size: 30px;
    font-weight: 500;
    color: ${COLOR.WHITE_1};
    margin-bottom: 24px;
  }
  h2 {
    font-size: 24px;
    font-weight: 500;
  }
  a:hover {
    color: ${COLOR.CORAL_2};
  }
  // Tablet-Notebook
  @media screen and (min-width: ${BREAKPOINT.MD}) {
    h1 {
      font-size: 44px;
      text-shadow: -1px 0 5px rgba(0,0,0,0.3);
    }
    h2 {
      font-size: 26px;
    }
  }
  // Desktop
  @media screen and (min-width: ${BREAKPOINT.LG}) {
    h1 {
      font-size: 48px;
    }
    h2 {
      font-size: 28px;
    }
  }
`;

export const Hero = styled.section`
  background-color: ${COLOR.CORAL_1};
  margin-top: 70px;
  padding: 30px 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom -2%;

  .wrapper > a {
    font-weight: 400;
    font-size: 18px;
    color: ${COLOR.WHITE_1};
    text-decoration: underline;
    white-space: nowrap;
    &:after {
      content: '\\25BE';
      display: inline-block;
      margin-left: 10px;
    }
  }
  // Viewport <= 500px
  @media screen and (min-width: 500px) {
    background-size: cover;
  }
   // Tablet-Notebook
  @media screen and (min-width: ${BREAKPOINT.MD}) {
    padding: 90px;
    min-height: 860px;
    .wrapper > a {
      font-size: 20px;
      text-shadow: -1px 0 5px rgba(0,0,0,0.3);
    }
  }
  // Viewport <= 960px
  @media screen and (min-width: 970px) {
    background-position: center bottom 30%;
    margin-top: 0;
    padding-top: 150px;
  }
  // Viewport <= 1152px
  @media screen and (min-width: 1152px) {
    background-size: 1152px;
    background-position: center top;
  }
  // Desktop
  @media screen and (min-width: ${BREAKPOINT.LG}) {
    .wrapper > a {
      font-size: 22px;
    }
  }
`;

export const Card = styled.div`
  position: relative;
  background-color: ${COLOR.WHITE_1};
  box-shadow: 0px 1px 2px 1px rgba(70, 77, 92, 0.2);
  border-radius: 4px; 
  margin: 44px auto 60px;
  padding: 24px;
  max-width: 483px;
  .titleNews {
    font-size: 18px;
    font-weight: 500;
    color: ${COLOR.BLACK_1};
    margin-bottom: 18px;
    line-height: 1.2;
  }
  & > p {
    font-size: 18px;
    color: ${COLOR.BLACK_1};
    line-height: 1.2;
    margin: 15px 0;
    font-weight: 400;
  }
  & > p:nth-child(5) {
    margin-bottom: 80px;
  }
  button {
    width: 100%;
  }
  .MuiInputBase-root {
    margin: 0;
    width: 100%;
  }
  // Tablet-Notebook
  @media screen and (min-width: ${BREAKPOINT.MD}) {
    padding: 32px;
    .titleNews {
      font-size: 20px;
    }
  }
  // Viewport <= 970px
  @media screen and (min-width: 970px) {
    margin-left: 0;
  }
  // Desktop
  @media screen and (min-width: ${BREAKPOINT.LG}) {
    .titleNews {
      font-size: 22px;
    }
  }
`;

export const Quote = styled.div`
  text-align: center;
  padding: 0 24px;
  position: relative;
  max-width: 700px;
  margin: 0 auto;
  background-repeat: no-repeat, no-repeat;
  background-position: left top, right bottom;
  
  q {
    display: block;
    color: ${COLOR.WHITE_1};
    font-size: 18px;
    line-height: 1.3;
    font-weight: 400;
    margin: 8px 0;
    text-shadow: -1px 0 5px rgba(0,0,0,0.3);
  }
  // Tablet-Notebook
  @media screen and (min-width: ${BREAKPOINT.MD}) {
    margin-top: 100px;
    background-size: 46px, 46px;
    padding: 0 50px;
    q {
      font-size: 20px;
    }
  }
  /* Viewport <= 700px */
  @media screen and (min-width: 970px) {
    margin-top: 200px;
  }
  // Desktop
  @media screen and (min-width: ${BREAKPOINT.LG}) {
    q {
      font-size: 22px;
    }
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  margin: 14px 0;
  align-items: end;
  line-height: 1.2;
  a {
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
  }
  .MuiIconButton-root {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
  }
  /* Tablet-Notebook 2 */
  @media screen and (min-width: ${BREAKPOINT.MD_2}) {
    margin: 25px 0;
    p {
      font-size: 16px
    };
  }
`;

export const About = styled.section`
  margin: 60px 0;
  padding: 50px 24px;
  background-repeat: no-repeat, no-repeat;
  background-position: left 10px top, right bottom 20px;
  & .wrapper div > div {
    max-width: 700px;
  }
  h3 {
    color: ${COLOR.BLACK_1};
    line-height: 1.2;
    text-align: center;
    font-weight: 500;
    font-size: 26px;
  }
  h4 {
    margin-bottom: 28px;
  }
  & .wrapper div > img {
    width: 202px;
    height: 410px;
  }
  /* Viewport <= 970px */
  @media screen and (min-width: 970px) {
    .wrapper > div {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    & .wrapper div > div {
      margin-left: 0;
      margin-right: 0;
    }
  }
  // Tablet-Notebook
  @media screen and (min-width: ${BREAKPOINT.MD}) {
    h3 {
      font-size: 26px;
    }  
  }
`;

export const CardButton = styled.a`
  cursor: pointer;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${COLOR.GREEN_1};
  color: ${COLOR.WHITE_1};
  padding: 18px 40px 18px 14px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 0px 0px 4px 4px;
  line-height: 1.2;
  transition: background-color 400ms ease;
  &:hover {
    color: ${COLOR.WHITE_1} !important;
    background-color: ${COLOR.GREEN_2};
  }
  &:after {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    content: '\\25BA';
  }
  // Tablet-Notebook
  @media screen and (min-width: ${BREAKPOINT.MD}) {
    text-align: center;
  }
`;

export const Contact = styled.section`
  background: ${COLOR.WHITE_1};
  padding: 30px 24px 20px;
  h2 {
    font-size: 24px;
    color: ${COLOR.CORAL_1};
    font-weight: 500;
    text-align: left;
    line-height: 1.2;
  }
  form {
    max-width: 400px;
    margin: 32px auto;
  }
  h3 {
    color: ${COLOR.BLACK_1};
    font-size: 18px;
    margin: 24px 0;
    font-weight: 500;
  }
  li {
    font-size: 16px;
    margin: 10px 0;
    line-height: 1.2;
    font-weight: 300;
  }
  li a {
    text-decoration: underline;
  }
  .MuiButton-root {
    width: 100%;
    margin: 6px 0;
  }
  .social {
    display: flex;
    li {
      margin-right: 24px;
    }
  }
  .MuiInputBase-root {
    width: 100%;
    margin: 6px 0;
  }
  textarea {
    width: 100%;
    margin: 6px 0;
  }
  /* Viewport <= 500px */
  @media screen and (min-width: 500px) {
    h2 {
      text-align: center;
    }
  }
  /* Tablet-Notebook 2 */
  @media screen and (min-width: ${BREAKPOINT.MD_2}) {
    padding-top: 75px;
    .wrapper {
      display: flex;
      justify-content: space-around;
    }
    form {
      width: 460px;
      margin: 0;
    }
    h2 {
      font-size: 26px;
    }
    h3 {
      margin-top: 0;
      font-size: 26px;
    }
    li {
      font-size: 18px;
    }
    & > div {
      margin-top: 50px;
    }
    .social img {
      width: 40px;
    }
  }
`;

export const Footer = styled.footer`
  padding: 0 24px 40px;
  h3 {
    color: ${COLOR.BLACK_1};
    font-size: 18px;
    margin: 24px 0;
    font-weight: 500;
  }
  p, address {
    font-size: 16px;
    margin: 10px 0;
    line-height: 1.4;
    font-weight: 300;
  }
  /* Tablet-Notebook */
  @media screen and (min-width: ${BREAKPOINT.MD_2}) {
    background: ${COLOR.CORAL_1};
    text-align: center;
    padding: 30px 0;
    margin-top: 60px;
    h3 {
      font-size: 20px;
    }
    *, h3 {
      color: ${COLOR.WHITE_1};
    }
    p, address {
      font-size: 18px;
    }
  }
`;

export const Container = styled.div`
  max-width: 1152px;
  margin: 0 auto;
  ${({ info, css }) => info && css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    div {
      margin: 0;
    }
    div:first-child {
      width: 100%;
    }
    /* Tablet-Notebook 2 */
    @media screen and (min-width: ${BREAKPOINT.MD_2}) {
      justify-content: space-between !important;
      div:first-child {
        width: 100%;
        margin-bottom: 50px;
      }
      div:nth-child(2) {
        width: 47%;
      }
      div:last-child {
        width: 47%;
      }
    }
  `}
`;
