/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import Checkbox from '@material-ui/core/Checkbox';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import AlertCookies from 'components/AlertCookies';
import { Button } from 'components/Button';
import { Input, TextArea } from 'components/Input';
import { Blog } from 'components/Blog';
import Alert, { ALERT_TYPE } from 'components/Alert';
import EulaModal from 'components/EulaModal';

import Aspas1 from 'assets/icons/aspas1.svg';
import Aspas2 from 'assets/icons/aspas2.svg';
import LogoLoit from 'assets/icons/logo-loit.svg';
import Detail1 from 'assets/icons/detail-1.svg';
import Detail2 from 'assets/icons/detail-2.svg';
import Detail3 from 'assets/icons/detail-3.svg';
import Facebook from 'assets/icons/logo-facebook.svg';
import LinkedIn from 'assets/icons/logo-linkedin.svg';
import Instagram from 'assets/icons/logo-instagram.svg';
// import Twitter from 'assets/icons/logo-twitter.svg';

import Phone from 'assets/images/phone.jpg';
import BackgroundMobile from 'assets/images/background.jpg';
import BackgroundDesktop from 'assets/images/background-2.png';

import Menu from './Menu';

import {
  Wrapper, Hero, Card,
  Quote, CheckBoxWrapper,
  About, CardButton,
  Contact, Footer, Container,
} from './styles';

const Landing = () => {
  const [errorPost, setErrorPost] = useState(false);
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);
  const [loadingNewsletter, setLoadingNewsletter] = useState(false);
  const [contact, setContact] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [loadingContact, setLoadingContact] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');

  const isDesktop = useMediaQuery({ query: '(min-width: 970px)' });

  const background = isDesktop ? BackgroundDesktop : BackgroundMobile;

  const detail = isDesktop ? Detail3 : Detail2;

  const sendGridApiUrl = process.env.REACT_APP_API_EMAIL_URL;
  const sendGridKey = process.env.REACT_APP_SENDGRID_API_KEY;

  const handleChange = (e) => setEmail(e.target.value);

  const handleChangeContact = (e) => {
    setContact({
      ...contact,
      [e.target.name]: e.target.value,
    });
  };

  const showModal = () => setOpen(true);

  const closeModal = () => setOpen(false);

  const handleCheck = (e) => setChecked(e.target.checked);

  // Altera state no scroll da página
  const listenScrollEvent = () => {
    if (window.scrollY > 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    // Add evento do scroll
    window.addEventListener('scroll', listenScrollEvent);

    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  const setAlert = (text, type) => {
    setAlertText(text);
    setAlertType(type);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingNewsletter(true);

    const payload = {
      name: "",
      email,
      message: "Assinou Newsletter LOIT",
      product: 'LOIT',
    };

    await axios.post(
      sendGridApiUrl,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          'X-API-KEY': sendGridKey,
        },
      },
    ).then(() => {
      // Evento do Intercom
      window.Intercom('trackEvent', 'LOIT contato form');

      setEmail('');
      setChecked(false);
      setAlert('Solicitação enviada com sucesso!', ALERT_TYPE.SUCCESS);
    }).catch(() => {
      setAlert('Desculpe, ocorreu um erro ao solicitar rebimento de notícias!', ALERT_TYPE.ERROR);
    }).finally(() => {
      setLoadingNewsletter(false);
    });
  };

  const submitContact = async (e) => {
    e.preventDefault();
    setLoadingContact(true);

    const payload = {
      ...contact,
      product: 'LOIT',
    };

    await axios.post(
      sendGridApiUrl,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          'X-API-KEY': sendGridKey,
        },
      },
    ).then(() => {
      // Evento do Intercom
      window.Intercom('trackEvent', 'LOIT contato form');

      setContact({
        name: '',
        email: '',
        message: '',
      });
      setAlert('Mensagem enviada com sucesso!', ALERT_TYPE.SUCCESS);
    }).catch(() => {
      setAlert('Desculpe, ocorreu um erro ao enviar a mensagem!', ALERT_TYPE.ERROR);
    }).finally(() => {
      setLoadingContact(false);
    });
  };

  return (
    <Wrapper>
      {/* POLÍTICA DE PRIVACIDADE */}
      <EulaModal
        open={open}
        onClose={closeModal}
      />
      <Menu isScrolled={isScrolled} />
      {/* Seção Hero */}
      <Hero style={{ backgroundImage: `url(${background})` }}>
        <Container className="wrapper">
          <h1>O acesso ao direito como escolha</h1>
          <AnchorLink href="#about-loit" offset="100">
            Saiba o que não estão te contando
          </AnchorLink>
          {/* CARD */}
          <Card>
            <p className="titleNews">Quer receber nossas notícias em primeira mão?</p>
            <form onSubmit={handleSubmit}>
              {/* E-mail */}
              <Input
                icon
                required
                name="email"
                type="email"
                value={email}
                onChange={handleChange}
                placeholder="Digite aqui o seu e-mail"
              />
              {/* Política de Privacidade */}
              <CheckBoxWrapper>
                <Checkbox
                  required
                  checked={checked}
                  onChange={handleCheck}
                  size="small"
                  color="secondary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <p>
                  Ao continuar você declara ter lido e estar de acordo com a {' '}
                  <a onClick={showModal}>Política de Privacidade.</a>
                </p>
              </CheckBoxWrapper>
              {/* TODO: Submit newslatter */}
              <Button type="submit" loading={loadingNewsletter}>
                Solicitar notícias
              </Button>
            </form>
          </Card>
          <Quote style={{ backgroundImage: `url(${Aspas1}), url(${Aspas2})` }}>
            <h2>
              <q>Desenvolvemos ferramentas digitais de acesso à justiça para que as pessoas lutem pelos seus direitos de forma autônoma.</q>
              <q>Queremos você consciente e acessando seus direitos.</q>
            </h2>
          </Quote>
        </Container>
      </Hero>
      {/* Sobre o LOIT FGTS */}
      <About
        id="about-loit"
        style={{ backgroundImage: `url(${Detail1}), url(${detail})` }}
      >
        <Container className="wrapper">
          <h3>Sabia que o seu saldo do FGTS não é corrigido adequadamente desde 1999?</h3>
          {/* CARD */}
          <div>
            <Card>
              <h4>
                <img src={LogoLoit} alt="LOIT FGTS" title="LOIT FGTS" />
              </h4>
              <p>A correção do saldo do <abbr title="Fundo de Garantia do Tempo de Serviço">FGTS</abbr> é calculado por um índice que não acompanhou a inflação, trazendo <strong>perdas financeiras para os titulares do FGTS.</strong></p>
              <p>Os trabalhadores brasileiros que mantiveram, durante este período, algum contrato de trabalho em regime <abbr title="Consolidação das Leis do Trabalho">CLT</abbr>, contribuindo com o Fundo de Garantia por Tempo de Serviço, podem pedir revisão pleiteando a diferença na justiça.</p>
              <p>Você não precisa contratar um advogado para essa causa, pode usar diretamente o site da Justiça Federal da sua região.</p>
              <p>Esse procedimento é gratuito!</p>
              <CardButton
                href={`${process.env.REACT_APP_LOIT_URL}`}
              >
                Calcule o valor da sua revisão sem compromisso
              </CardButton>
            </Card>
            {
              isDesktop && (
                <img
                  src={Phone}
                  alt="LOIT FGTS - Versão Mobile"
                  title="LOIT FGTS - Versão Mobile"
                />
              )
            }
          </div>
        </Container>
      </About>
       {/* BLOG (TODO) */}
      {!errorPost && (
        <Blog status={status => setErrorPost(status)} />
      )}

      {/* Contato */}
      <Contact>
        <h2>Entre em contato e tire as suas dúvidas</h2>
        <Container className="wrapper">
          <div>
            {/* TODO: Submit form */}
            <form onSubmit={submitContact}>
              {/* Nome */}
              <Input
                icon
                required
                name="name"
                type="text"
                value={contact.name}
                onChange={handleChangeContact}
                placeholder="Nome"
              />
              {/* E-mail */}
              <Input
                icon
                required
                name="email"
                type="email"
                value={contact.email}
                onChange={handleChangeContact}
                placeholder="E-mail"
              />
              {/* Mensagem */}
              <TextArea
                required
                name="message"
                value={contact.message}
                onChange={handleChangeContact}
                placeholder="Digite sua mensagem..."
              />
              <Button
                type="submit"
                loading={loadingContact}
              >
                Enviar mensagem
              </Button>
            </form>
          </div>
          {/* Mídia Social */}
          <div>
            <h3>Estamos nas mídias</h3>
            <ul className="social">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/tikaltech/"
                >
                  <img src={Facebook} alt="Facebook" title="Facebook" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/tikal-tech/"
                >
                  <img src={LinkedIn} alt="LinkedIn" title="LinkedIn" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/loit_legal/"
                >
                  <img src={Instagram} alt="Instagram" title="Instagram" />
                </a>
              </li>
            </ul>
            <ul>
              {/* <li>
                WhatsApp:&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://api.whatsapp.com/send?phone=5548996017254"
                >
                  (48) 99601-7254
                </a>
              </li> */}
              <li>
                <a href="mailto:atendimento@loitlegal.com.br">
                  atendimento@loitlegal.com.br
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </Contact>
      {/* Rodapé */}
      <Footer>
        <h3>LOIT é um produto TIKAL TECH</h3>
        <p>© 2021 TIKAL TECH - Tia Tikal Inteligência Artificial LTDA - CNPJ: 22.125.577/0001-62</p>
        <address>Av. Queiroz Filho, 1700 - São Paulo - SP, CEP 05319-000</address>
      </Footer>
      {/* ALERTA */}
      <Alert
        type={alertType}
        open={!!alertType}
        callback={() => setAlertType('', null)}
      >
        {alertText}
      </Alert>
      <AlertCookies />
    </Wrapper>
  );
}

export default Landing;