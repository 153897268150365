/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import PATH from 'routes/path';

import { Button } from 'components/Button';

import { COLOR } from 'assets/styles/colors';
import BREAKPOINT from 'assets/styles/breakpoints';
import menuMobile from 'assets/icons/menu-mobile.svg';

import Logo from './Logo';

import {
  Header,
  Container,
  StyledIcon,
  StyledMenu,
  HeaderDesktop,
  StyledMenuItem,
} from './styles';

// Menu externo
const Menu = ({ isScrolled }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMobile = useMediaQuery({ query: `(max-width: ${BREAKPOINT.MD_2})` });

  // const color = isScrolled ? COLOR.WHITE_1 : COLOR.WHITE_1;
  const background = isScrolled ? COLOR.CORAL_1 : 'transparent';

  // Menu Mobile (com dropdown)
  const getMobileMenu = () => (
    <nav>
      <StyledIcon
        onClick={handleClick}
        color="primary"
      >
        <img src={menuMobile} alt="menu" />
      </StyledIcon>
      <StyledMenu
        keepMounted
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={handleClose}>
          <a
            target="_blank"
            rel="noreferrer"
            href={process.env.REACT_APP_LOIT_BLOG}
          >
            <Button
              variant="outlined"
              style={{
                border: 0,
                justifyContent: 'flex-start',
              }}
            >
              Blog
            </Button>
          </a>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClose}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://ajuda.tikal.tech/pt-BR/collections/2748191-loit-fgts"
          >
            <Button
              variant="outlined"
              style={{ border: 0 }}
            >
              Central de ajuda
            </Button>
          </a>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClose}>
          <a
            href={process.env.REACT_APP_LOIT_URL}
          >
            <Button
              variant="outlined"
              style={{ border: 0 }}
            >
              loit fgts
            </Button>
          </a>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClose} last="true">
          <a
            href={`${process.env.REACT_APP_LOIT_URL}login`}
          >
            <Button variant="outlined">
              Login loit fgts
            </Button>
          </a>
        </StyledMenuItem>
      </StyledMenu>
    </nav>
  );

  // Menu Desktop
  const getDesktopMenu = () => (
    <nav>
      <Container>
        <Logo color={COLOR.WHITE_1} />
        <nav>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href={process.env.REACT_APP_LOIT_BLOG}
              >
                <Button>Blog</Button>
              </a>
            </li>
            <li>
              <a
                href="https://ajuda.tikal.tech/pt-BR/collections/2748191-loit-fgts"
                target="_blank"
                rel="noreferrer"
              >
                <Button>Central de ajuda</Button>
              </a>
            </li>
            <li>
              <a
                href={process.env.REACT_APP_LOIT_URL}
              >
                <Button>loit fgts</Button>
              </a>
            </li>
            <li>
              <a
                href={`${process.env.REACT_APP_LOIT_URL}login`}
              >
                <Button>Login loit fgts</Button>
              </a>
            </li>
          </ul>
        </nav>
      </Container>
    </nav>
  );

  // Desktop Menu
  if (!isMobile) {
    return (
      <HeaderDesktop background={background}>
        {getDesktopMenu()}
      </HeaderDesktop>
    );
  }

  // Mobile Menu
  return (
    <Header>
      <Container>
        <Logo color={COLOR.WHITE_1} />
        {getMobileMenu() }
      </Container>
    </Header>
  );
};

Menu.propTypes = {
  isScrolled: PropTypes.bool.isRequired,
};

export default Menu;
