import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';

import PersonIcon from 'assets/icons/person.svg';
import EmailIcon from 'assets/icons/email.svg';
import PasswordIcon from 'assets/icons/password.svg';
import PhoneIcon from 'assets/icons/phone.svg';

import {
  StyledInput,
  StyledTextArea,
  StyledLabelInput,
  StyledFormHelperText,
} from './styles';

function getIcon(type) {
  switch (type) {
    case 'text':
      return PersonIcon;
    case 'email':
      return EmailIcon;
    case 'password':
      return PasswordIcon;
    case 'tel':
      return PhoneIcon;
    default:
      return PasswordIcon;
  }
}

function getAdorment(type) {
  const inputIcon = getIcon(type);

  return (
    <InputAdornment position="start">
      <img
        src={inputIcon}
        alt={`${type} input icon`}
      />
    </InputAdornment>
  );
}

/*
  ## Inputs ##
*/

export const Input = ({ icon, endIcon, ...params }) => (
  <div style={{ width: 'auto' }}>
    <StyledInput
      {...params}
      variant="outlined"
      startAdornment={icon ? getAdorment(params.type) : null}
      endAdornment={endIcon ? getAdorment(params.type) : null}
    />
    {
      params.helperText && (
        <StyledFormHelperText>
          {params.helperText}
        </StyledFormHelperText>
      )
    }
  </div>
);

Input.defaultProps = {
  icon: false,
  endIcon: false,
};

Input.propTypes = {
  icon: PropTypes.bool,
  endIcon: PropTypes.bool,
};

export const LabelInput = ({ icon, endIcon, ...params }) => (
  <StyledLabelInput
    {...params}
    variant="outlined"
    InputProps={{
      startAdornment: icon ? getAdorment(params.type) : null,
      endAdornment: endIcon ? getAdorment(params.type) : null,
    }}
  />
);

LabelInput.defaultProps = {
  icon: false,
  endIcon: false,
};

LabelInput.propTypes = {
  icon: PropTypes.bool,
  endIcon: PropTypes.bool,
};

/*
  ## Inputs de senha (Exibe/oculta senha) ##
*/

export const InputPassword = ({ ...params }) => {
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });

  function handleClickShowPassword() {
    setValues({ ...values, showPassword: !values.showPassword });
  }

  function getShowButton() {
    return (
      <InputAdornment position="end">
        <IconButton
          edge="end"
          onClick={handleClickShowPassword}
        >
          {values.showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  }

  return (
    <div style={{ width: 'auto' }}>
      <StyledInput
        {...params}
        variant="outlined"
        type={values.showPassword ? 'text' : 'password'}
        endAdornment={getShowButton()}
        startAdornment={getAdorment('password')}
      />
      {
        params.helperText && (
          <StyledFormHelperText>
            {params.helperText}
          </StyledFormHelperText>
        )
      }
    </div>
  );
};

/*
  ## Textarea ##
*/

export const TextArea = ({ ...params }) => (
  <StyledTextArea {...params} />
);
