import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import axios from 'axios';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { 
    Container, BoxCards, Title, 
    ContainerDescription, TitlePost, DescriptionText, 
    Card 
} from './styles.js';
import ImgPost from 'assets/icons/blog-img.svg';
import NextIcon from 'assets/icons/next-posts.svg';
import BackIcon from 'assets/icons/back-posts.svg';
import { TrendingUpOutlined } from '@material-ui/icons';

export const Blog = ({ status }) => {
    const [posts, setPosts] = useState([]);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        accessibility: true,
        responsive: [
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 699,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    const getPosts = async () => {
        await axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@loit')
        .then((res) => {
            setPosts(res.data.items);
            status(false);
        })
        .catch((error) => {
            status(true);
        });
    }

    useEffect(() => {
        getPosts();
    }, []);

    return (
        <Container>
            <Title>Blog loit</Title>
            <BoxCards>
                <Slider 
                    {...settings} 
                    nextArrow={<img src={NextIcon} alt="next post icon" />}
                    prevArrow={<img src={BackIcon} alt="back post icon" />}
                >
                    {posts.map((post, index) => {
                        return (
                            <Card key={index}>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={post.link}
                                >
                                    <img src={post.thumbnail ? post.thumbnail : ImgPost} alt="post image" />
                                    <ContainerDescription>
                                        <TitlePost>{post.title}</TitlePost>
                                        <DescriptionText>{post.description.replace( /(<([^>]+)>)/ig, '')}</DescriptionText>
                                    </ContainerDescription>
                                </a>
                            </Card>
                        )
                    })}
                </Slider>
            </BoxCards>
        </Container>
    );
}

Blog.propTypes = {
    status: PropTypes.bool.isRequired,
};
  
Blog.defaultProps = {
    status: true,
};
