import { createMuiTheme } from '@material-ui/core/styles';

import { COLOR } from 'assets/styles/colors';

/*
  ## Main Theme ##
*/

const MainTheme = createMuiTheme({
  // Tipografia
  typography: {
    fontSize: 16,
    fontFamily: 'Barlow, sans-serif',
  },
  // Paleta de cores (Primária e Secundária)
  palette: {
    primary: {
      main: COLOR.WHITE_1,
    },
    secondary: {
      main: COLOR.CORAL_1,
    },
  },
  // Espaçamento
  spacing: 2,
  // Breakpoints
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 700,
      lg: 1440,
      xl: 1700,
    },
  },
});

export default MainTheme;