/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';

import { StyledAlert } from './styles';

const ANCHOR_STYLE = Object.freeze({
  vertical: 'top',
  horizontal: 'center',
});

export const ALERT_TYPE = Object.freeze({
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
});

/*
  # Alertas #
*/

const Alert = ({
  duration,
  open, type,
  callback, children,
}) => (
  <Snackbar
    open={open}
    onClose={callback}
    autoHideDuration={duration}
    anchorOrigin={ANCHOR_STYLE}
  >
    <StyledAlert
      severity={type}
      onClose={callback}
    >
      {children}
    </StyledAlert>
  </Snackbar>
);

Alert.propTypes = {
  duration: PropTypes.number,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  callback: PropTypes.func.isRequired,
};

Alert.defaultProps = {
  duration: 5000,
};

export default Alert;
