/*
  ## Breakpoints ##
*/

const BREAKPOINT = Object.freeze({
  // Mobile
  SM: '320px',
  // Tablet-Notebook
  MD: '700px',
  // Tablet-Notebook 2
  MD_2: '800px',
  // Desktop
  LG: '1440px',
  // Extra Large
  XL: '1700px',
});

export default BREAKPOINT;
