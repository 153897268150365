import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { COLOR } from 'assets/styles/colors';

/*
  ## Botões (Contained e Outlined) ##
*/

export const StyledButton = withStyles((theme) => ({
  root: {
    height: 36,
    width: 240,
    margin: theme.spacing(3),
    fontSize: 18,
    fontWeight: 500,
    color: COLOR.BLACK_1,
    textTransform: 'none',
    borderRadius: 6,
    whiteSpace: 'nowrap',
    transition: 'background-color 400ms ease',
    '&:hover': {
      boxShadow: '2px 3px 4px rgba(17, 90, 145, 0.5)',
    },
    // Tablet-Notebook
    [theme.breakpoints.up('md')]: {
      width: 260,
      height: 40,
      fontSize: 20,
    },
    // Desktop
    [theme.breakpoints.up('lg')]: {
      fontSize: 22,
    },
  },
  // Contained
  contained: {
    color: COLOR.WHITE_1,
    backgroundColor: COLOR.CORAL_1,
    '&:hover': {
      color: COLOR.WHITE_1,
      backgroundColor: COLOR.CORAL_2,
    },
    // Disabled
    '&.Mui-disabled': {
      color: COLOR.WHITE_1,
      backgroundColor: COLOR.GREY_1,
      width: '100%',
    },
  },
  // Outlined
  outlined: {
    color: COLOR.BLACK_1,
    backgroundColor: COLOR.WHITE_1,
    border: `2px solid ${COLOR.BLACK_1}`,
    '&:hover': {
      color: COLOR.BLACK_1,
      border: `2px solid ${COLOR.BLACK_1}`,
      boxShadow: '2px 3px 4px rgba(33, 123, 115, 0.5',
    },
  },
}))(Button);
