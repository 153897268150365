import styled from 'styled-components';
import BREAKPOINT from 'assets/styles/breakpoints';
import { COLOR } from 'assets/styles/colors';

export const Container = styled.div`
  background-color: ${COLOR.GREY_3};
  padding: 44px 36px 88px 36px;

    // Tablet-Notebook
    @media screen and (min-width: ${BREAKPOINT.MD}) {
        padding: 70px 48px 150px 48px;
    }
    // Desktop
    @media screen and (min-width: ${BREAKPOINT.LG}) {
        padding: 70px 90px 150px 90px;
    }
`;

export const BoxCards = styled.div`
    max-width: 1100px;
    margin: 0 auto;
    .slick-dots {
        bottom: -60px;
        .slick-active {
            width: 97px;
        }
    }
    .slick-dots li {
        background: ${COLOR.CORAL_1};
        width: 40px;
        height: 6px;
        border-radius: 50px;
    }
    .slick-dots li button:before {
        content: '';
    }
    .slick-dots li button {
        width: 100%;
    }
    // Tablet-Notebook
    @media screen and (min-width: ${BREAKPOINT.MD}) {
        .slick-dots {
            bottom: -80px;
            .slick-active {
                width: 137px;
            }
        }
        .slick-dots li {
            width: 59px;
            height: 6px;
        }
    }
`;

export const Title = styled.h2`
    margin-bottom: 32px;
    font-family: Barlow;
    font-weight: 600;
    font-size: 24px;
    line-height: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLOR.CORAL_1};
    // Tablet-Notebook
    @media screen and (min-width: ${BREAKPOINT.MD}) {
        margin-bottom: 50px;
        font-size: 26px;
    }
`;

export const Card = styled.div`
    display: flex;
    width: 33%;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        width: 280px !important;
        height: 178px;
        width: 100%;
        border-radius: 4px;
        margin: 0 auto;
    }
`;

export const ContainerDescription = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    text-align: left;
    max-width: 280px;
    flex-direction: column;
    border-left: 1px solid ${COLOR.CORAL_1};
    margin: 18px auto 0 auto;
    // Tablet-Notebook
    @media screen and (min-width: ${BREAKPOINT.MD}) {
        margin: 28px auto 0 auto;
    }
`;

export const TitlePost = styled.p`
    font-family: Barlow;
    font-weight: 600;
    font-size: 22px;
    margin-left: 14px;
    line-height: 110%;
    color: ${COLOR.BLACK_1};
`;

export const DescriptionText = styled.p`
    width: 95%;
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    color: ${COLOR.GREY_1};
    margin-top: 14px;
    margin-left: 14px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    // Tablet-Notebook
    @media screen and (min-width: ${BREAKPOINT.MD}) {
        width: 100%;
        font-size: 18px;
    }
`;