import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LoitLogo = ({ color }) => (
  <svg width="71" height="40" viewBox="0 0 72 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.569319 39.9437C0.189773 39.9437 0 39.7562 0 39.3812V1.12517C0 0.750116 0.189773 0.562586 0.569319 0.562586C1.93178 0.562586 4.04216 1.96869 4.04216 3.37516V39.3812C4.04216 39.7562 3.85239 39.9437 3.47285 39.9437H0.569319Z" fill={color} />
    <path d="M24.1089 39.9434C21.3761 39.9434 19.023 39.2495 17.0493 37.8618C15.0757 36.4741 13.7093 34.5613 12.9502 32.1234C12.4189 30.3981 12.1532 28.0915 12.1532 25.2036C12.1532 22.3156 12.4189 20.0278 12.9502 18.34C13.6714 15.9396 15.0188 14.0456 16.9924 12.6579C18.966 11.2701 21.3572 10.5763 24.1658 10.5763C26.8606 10.5763 29.1758 11.2701 31.1115 12.6579C33.0851 14.0456 34.4325 15.9209 35.1537 18.2837C35.685 19.8965 35.9507 22.2031 35.9507 25.2036C35.9507 28.2415 35.685 30.5482 35.1537 32.1234C34.4325 34.5613 33.0851 36.4741 31.1115 37.8618C29.1758 39.2495 26.8416 39.9434 24.1089 39.9434ZM24.1089 36.4553C25.9307 36.4553 27.4868 35.9678 28.7773 34.9926C30.0678 33.9799 30.9407 32.6297 31.3962 30.942C31.7378 29.5918 31.9086 27.6977 31.9086 25.2598C31.9086 22.7844 31.7567 20.8904 31.4531 19.5777C30.9976 17.8899 30.1057 16.5585 28.7773 15.5833C27.4868 14.5707 25.9117 14.0643 24.0519 14.0643C22.1922 14.0643 20.6171 14.5707 19.3266 15.5833C18.0361 16.5585 17.1632 17.8899 16.7077 19.5777C16.4041 20.8904 16.2523 22.7844 16.2523 25.2598C16.2523 27.7352 16.4041 29.6293 16.7077 30.942C17.1252 32.6297 17.9792 33.9799 19.2697 34.9926C20.5981 35.9678 22.2111 36.4553 24.1089 36.4553Z" fill={color} />
    <path d="M46.8772 6.30098C45.9663 6.30098 45.2072 6.00094 44.5999 5.40084C43.9926 4.80075 43.689 4.05063 43.689 3.15049C43.689 2.21285 43.9926 1.46273 44.5999 0.900143C45.2072 0.300048 45.9663 0 46.8772 0C47.7881 0 48.5472 0.300048 49.1545 0.900143C49.7617 1.46273 50.0654 2.21285 50.0654 3.15049C50.0654 4.05063 49.7617 4.80075 49.1545 5.40084C48.5472 6.00094 47.7881 6.30098 46.8772 6.30098ZM45.2831 39.8875C44.9035 39.8875 44.7138 39.7 44.7138 39.3249V11.9831C44.7138 11.6081 44.9035 11.4205 45.2831 11.4205H48.1866C48.5662 11.4205 48.7559 11.6081 48.7559 11.9831V39.3249C48.7559 39.7 48.5662 39.8875 48.1866 39.8875H45.2831Z" fill={color} />
    <path d="M71.5789 14.2335C71.5789 14.6085 71.3892 14.7961 71.0096 14.7961H64.5763C64.4245 14.7961 64.3486 14.8711 64.3486 15.0211V31.6174C64.3486 33.4177 64.7471 34.6929 65.5442 35.443C66.3792 36.1556 67.6696 36.512 69.4155 36.512H70.8388C71.2184 36.512 71.4082 36.6995 71.4082 37.0745V39.3812C71.4082 39.7562 71.2184 39.9437 70.8388 39.9437C70.3834 39.9812 69.6812 40 68.7324 40C65.9996 40 63.9311 39.4937 62.5268 38.481C61.1225 37.4684 60.4203 35.5931 60.4203 32.8551V15.0211C60.4203 14.8711 60.3444 14.7961 60.1926 14.7961H56.7197C56.3402 14.7961 56.1504 14.6085 56.1504 14.2335V12.0394C56.1504 11.6643 56.3402 11.4768 56.7197 11.4768H60.1926C60.3444 11.4768 60.4203 11.4018 60.4203 11.2518V4.72574C60.4203 4.35068 60.6101 4.16315 60.9896 4.16315C62.6591 4.16315 64.3486 5.71928 64.3486 7.12575V11.2518C64.3486 11.4018 64.4245 11.4768 64.5763 11.4768H71.0096C71.3892 11.4768 71.5789 11.6643 71.5789 12.0394V14.2335Z" fill={color} />
  </svg>

);

LoitLogo.propTypes = {
  color: PropTypes.string.isRequired,
};

/*
  ## LOGO LOIT FGTS ##
*/

const Logo = ({ path, color }) => (
  <Link to={path} className="logo">
    <LoitLogo color={color} />
  </Link>
);

Logo.defaultProps = {
  path: '/',
};

Logo.propTypes = {
  path: PropTypes.string,
  color: PropTypes.string.isRequired,
};

export default Logo;
