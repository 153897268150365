import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';

/*
  ## Alert styles ##
*/

export const StyledAlert = withStyles(() => ({
  root: {
    minWidth: 310,
    fontSize: 14,
    marginTop: 50,
    boxShadow: '0 0 10px rgba(0,0,0,0.2) !important',
  },
}))(Alert);
