import React from 'react';
import Router from 'routes';
import { ThemeProvider } from '@material-ui/core/styles';

import MainTheme from 'assets/styles/themes';

const App = () => (
  <ThemeProvider theme={MainTheme}>
    <Router />
  </ThemeProvider>
);

export default App;
