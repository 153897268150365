import styled from 'styled-components';

import {
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { COLOR } from 'assets/styles/colors';
import BREAKPOINT from 'assets/styles/breakpoints';

/*
  ## Input ##
*/

export const StyledInput = withStyles((theme) => ({
  root: {
    width: 240,
    height: 36,
    fontSize: 16,
    fontFamily: theme.fontFamily,
    color: COLOR.BLACK_1,
    margin: theme.spacing(3),
    backgroundColor: COLOR.WHITE_1,
    borderRadius: 6,
    border: `1px solid ${COLOR.GREY_1}`,
    // Tablet-Notebook
    [theme.breakpoints.up('md')]: {
      width: 260,
      height: 40,
      fontSize: 18,
    },
    // Desktop
    [theme.breakpoints.up('lg')]: {
      fontSize: 20,
    },
  },
  input: {
    '&::placeholder': {
      color: COLOR.GREY_1,
      opacity: 1,
    },
  },
  // on focus
  focused: {
    border: `2px solid ${COLOR.GREY_1}`,
  },
  notchedOutline: {
    border: 'none',
  },
  error: {
    border: `2px solid ${COLOR.CORAL_1}`,
  },
}))(OutlinedInput);

/*
  ## Helper text (errors) ##
*/

export const StyledFormHelperText = withStyles((theme) => ({
  root: {
    color: COLOR.CORAL_1,
    lineHeight: 1.4,
    margin: theme.spacing(0, 4, 4),
    fontSize: 12,
  },
}))(FormHelperText);

/*
  # Label Input #
*/

export const StyledLabelInput = styled(TextField)`
  width: 240px;
  background-color: ${COLOR.WHITE_1};
  .MuiFormControl-root {
    margin: 6px;
  }
  .MuiFormLabel-root {
    font-size: 16px;
    line-height: 13px;
    color: ${COLOR.GREY_1};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${COLOR.GREY_1};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 2px solid ${COLOR.GREY_1};
  }
  .Mui-error {
    margin-bottom: 20px;
  }
  .MuiFormHelperText-root {
    margin: 0 2px;
    position: absolute;
    bottom: -7px;
    font-size: 12px;
  }
  .MuiInputLabel-outlined {
    color: ${COLOR.GREY_1};
    transform: translate(14px, 80%) scale(1);
    &.MuiInputLabel-shrink {
      font-size: 16px !important;
      transform: translate(14px, -6px) scale(0.85);
    }
  }
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
  }
  .MuiInputBase-root {
    height: 36px;
  }
  .MuiOutlinedInput-root {
    border-radius: 6px;
  }
  .MuiOutlinedInput-input {
    padding: 0 14px;
    font-size: 16px;
    color: ${COLOR.GREY_2};
  }
  /* Tablet-Notebook  */
  @media screen and (min-width: ${BREAKPOINT.MD}) {
    width: 260px;
    .MuiFormHelperText-root {
      font-size: 12px;
    }
    .MuiInputBase-root {
      height: 40px;
    }
    .MuiFormLabel-root,
    .MuiOutlinedInput-input {
      font-size: 18px;
    }
  }
  /* Desktop */
  @media screen and (min-width: ${BREAKPOINT.LG}) {
    .MuiFormLabel-root,
    .MuiOutlinedInput-input {
      font-size: 20px;
    }
  }
`;

/*
  ## Textarea ##
*/

export const StyledTextArea = styled.textarea`
  font-size: 16px;
  width: 240px;
  height: 110px;
  color: ${COLOR.BLACK_1};
  border-radius: 6px;
  border: 1px solid ${COLOR.GREY_1};
  background-color: ${COLOR.WHITE_1};
  margin: 6px;
  outline: none;
  resize: none;
  padding: 10px;
  font-weight: 400;
  font-family: 'Barlow', sans-serif;
  &:focus {
    border: 2px solid ${COLOR.GREY_1};
  }
  &::placeholder {
    color: ${COLOR.GREY_1};
    font-weight: 400;
    font-size: inherit;
    font-family: 'Barlow', sans-serif;
  }
  @media screen and (min-width: ${BREAKPOINT.MD}) {
    font-size: 18px;
  }
`;
