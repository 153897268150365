/*
  ## System Colors ##
*/

export const COLOR = Object.freeze({
  CORAL_1: '#FF5B5B',
  CORAL_2: '#B34B4B',
  WHITE_1: '#FDFDFF',
  BLACK_1: '#464D5C',
  GREEN_1: '#2B938A',
  GREEN_2: '#217B73',
  GREY_1: '#949494',
  GREY_2: '#838383',
  GREY_3: '#F9F9F9',
});