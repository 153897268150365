import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { COLOR } from 'assets/styles/colors';

/*
  # Alerta de Cookies #
*/

const AlertCookies = () => (
  <CookieConsent
    buttonText="Entendi"
    style={{
      justifyContent: 'center',
      padding: 20,
    }}
    overlayStyle={{
      justifyContent: 'center',
      alignItems: 'center',
    }}
    contentStyle={{
      flex: 'initial',
      padding: 0,
      margin: '0 0 15px 0',
      fontFamily: 'Barlow',
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '20px',
    }}
    buttonStyle={{
      width: '100px',
      height: '30px',
      margin: '10 auto',
      padding: 0,
      backgroundColor: COLOR.CORAL_1,
      color: COLOR.WHITE_1,
      fontFamily: 'Barlow',
      fontSize: '16px',
      borderRadius: '15px',
    }}
  >
    Ao navegar neste site, você aceita os cookies que usamos para melhorar sua experiência.
    {' '}
    <a
      style={{
        marginRight: '25px',
        fontFamily: 'Barlow',
        fontSize: '16px',
        color: COLOR.WHITE_1,
        textDecoration: 'underline',
      }}
      href="https://ajuda.tikal.tech/pt-BR/articles/5339425-politica-de-uso-de-cookies"
      rel="noreferrer"
      target="_blank"
    >
      Mais informações.
    </a>
  </CookieConsent>
);

export default AlertCookies;
