import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

import { COLOR } from 'assets/styles/colors';
import BREAKPOINT from 'assets/styles/breakpoints';

export const StyledDialog = withStyles(() => ({
  paper: {
    margin: 18,
    overflowY: 'inherit',
  },
  paperWidthSm: {
    width: '100%',
    height: '80%',
    maxWidth: 588,
  },
  paperScrollPaper: {
    flexDirection: 'row',
  },
}))(Dialog);

export const Content = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 25px 14px;
  overflow-y: auto;
  h2 {
    position: relative;
    padding: 0 0 10px;
    margin-bottom: 25px;
    text-transform: initial;
    color: ${COLOR.BLACK_1};
    font-size: 24px;
    font-weight: 600;
    line-height: 125%;
    text-transform: uppercase;
    /* Tablet-Notebook */
    @media screen and (min-width: ${BREAKPOINT.MD}) {
      font-size: 26px;
    }
    /* Desktop */
    @media screen and (min-width: ${BREAKPOINT.LG}) {
      font-size: 28px;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100px;
      height: 4px;
      background-color: ${COLOR.GREEN_1};
    }
  } 
  a {
    font-weight: 500;
    text-decoration: underline;
  }

  p {
    color: ${COLOR.BLACK_1};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    margin: 10px 0;
    /* Tablet-Notebook */
    @media screen and (min-width: ${BREAKPOINT.MD}) {
      font-size: 18px;
    }
    /* Desktop */
    @media screen and (min-width: ${BREAKPOINT.LG}) {
      font-size: 20px;
    }
  }
  form {
    margin: 20px 0 0;
  }
  .MuiIconButton-root {
    position: absolute;
    top: 0;
    right: 0;
  }
  .MuiInputBase-root,
  .MuiButton-outlined,
  .MuiButton-contained {
    width: 100%;
    margin: 7px 0;
  }
  /* Tablet-Notebook */
  @media screen and (min-width: ${BREAKPOINT.MD}) {
    padding: 32px 48px;
  }
`;
