import styled from 'styled-components';
import {
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { COLOR } from 'assets/styles/colors';
import BREAKPOINT from 'assets/styles/breakpoints';

export const Header = styled.header`
  z-index: 10;
  position: fixed;
  height: 70px;
  top: 0;
  width: 100%;
  z-index: 200;
  padding: 10px 24px;
  background-color: ${COLOR.CORAL_1};
  display: flex;
  align-items: center;
  svg {
    width: 57px;
    height: 32px;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const MenuList = styled.ul`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1152px;
  margin: 0 auto;
  // Tablet-Notebook
  @media screen and (min-width: ${BREAKPOINT.MD_2}) {
    padding: 0;
  }
`;

export const StyledIcon = withStyles({
  root: {
    padding: 5,
  },
  label: {
    '& > svg': {
      width: 40,
      height: 40,
    },
    '& > svg > path': {
      width: '100%',
      height: '100%',
    },
  },
})(IconButton);

export const StyledMenuItem = withStyles({
  root: {
    '& button': {
      width: ({ last }) => (last ? '95%' : 'auto'),
      fontSize: 18,
      color: ({ last }) => (last ? COLOR.BLACK_1 : COLOR.CORAL_1),
      background: ({ last }) => (last && COLOR.WHITE_1),
      borderColor: ({ last }) => (last && COLOR.BLACK_1),
      margin: '5px 0',
      padding: ({ last }) => (last ? '10px 24px' : 0),
    },
    '& .MuiButton-outlined': {
      backgroundColor: 'transparent',
    },
    '& .MuiButton-outlined:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
})(MenuItem);

export const StyledMenu = withStyles((theme) => ({
  paper: {
    width: 196,
    right: 0,
    left: 'auto !important',
    marginTop: 60,
    borderRadius: '6px 0 0 6px',
    padding: '5px 0',
    '& ul > li': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '0 24px',
    },
    // Tablet-Notebook
    [theme.breakpoints.up('xl')]: {
      right: '15%',
    },
  },
}))(Menu);

export const HeaderDesktop = styled.header`
  background-color: ${COLOR.CORAL_1};
  z-index: 200;
  position: fixed;
  top: 0;
  width: 100%;
  height: 88px;
  padding: 24px;
  transition: background-color 400ms ease-in-out;
  nav ul {
    display: flex;
  }
  button {
    width: auto;
    margin: 0;
    text-shadow: -1px 0 5px rgba(0,0,0,0.3);
  }
  .logo {
    transform: scale(1);
    margin: 0;
  }
  li:nth-child(1) button,
  li:nth-child(2) button,
  li:nth-child(3) button {
    background: transparent;
    box-shadow: none;
    padding: 0;
  } 
  li:nth-child(4) button {
    background: ${COLOR.WHITE_1};
    color: ${COLOR.BLACK_1};
    text-shadow: none;
  }
  // Tablet-Notebook
  @media screen and (min-width: ${BREAKPOINT.MD}) {
    padding: 24px 90px;
  }
  // Tablet-Notebook
  @media screen and (min-width: ${BREAKPOINT.MD_2}) {
    button {
      margin: 0 14px;
      font-weight: 500 !important;
    }
  }
  // Viewport <= 970px 
  @media screen and (min-width: 970px) {
    background: ${(props) => props.background};
  }
  // Tablet-Notebook
  @media screen and (min-width: ${BREAKPOINT.MD_3}) {
    button {
      margin: 0 22px;
    }
    li:nth-child(4) button {
      margin: 0 22px;
    }
  }
`;
